<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "CalendarViews.EditCalendar",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <ActionButtons @methodSave="onSubmit('btn-save')" :isNewRecord="false" />
    </div>
  </div>
  <form class="form-calendar-edit pe-2 ps-2" novalidate>
    <div v-if="errors.length > 0" class="alert alert-warning">
      <ul class="mb-0">
        <li v-for="error in errors" v-bind:key="error">
          {{ error }}
        </li>
      </ul>
    </div>
    <div class="row row-cols-1">
      <div class="col col-md-4">
        <label for="Name" class="form-label required"
          >{{
            $t(
              "CalendarViews.Name",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="Name"
          v-model="calendarViewData.name"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
      <div class="col col-md-4">
        <label for="startDateField" class="form-label required">{{
          $t(
            "CalendarViews.StartDate",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          :selectedData="selectedStartDate"
          :isDetailIconShow="true"
          :routeLink="
            String.format(
              '#/CustomObject/EditField/{0}&fieldId=',
              this.$route.params.customObjectId
            )
          "
          @onChange="onChangeStartDate"
          :allowEmpty="false"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryCalendarViewableFields?parentId={0}&coId={0}',
              this.$route.params.customObjectId
            )
          "
        />
      </div>
      <div class="col col-md-4">
        <label for="endDateField" class="form-label">{{
          $t(
            "CalendarViews.EndDate",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithLocalData"
          :isDetailIconShow="true"
          :routeLink="
            String.format(
              '#/CustomObject/EditField/{0}&fieldId=',
              this.$route.params.customObjectId
            )
          "
          :data="endDateData"
          :selectedData="selectedEndDate"
          @onChange="onChangeEndDate"
          :isParameters="true"
        />
      </div>
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col col-md-4">
        <label for="TitleObject" class="form-label required">{{
          $t(
            "CalendarViews.TitleObject",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          :selectedData="selectedTitleObjects"
          @onMultipleSelectChange="onChangeTitleObject"
          :isMultiple="true"
          :allowEmpty="false"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryFields?parentId={0}&coId={0}',
              this.$route.params.customObjectId
            )
          "
        />
      </div>
      <div class="col col-md-4">
        <label for="Views" class="form-label required">{{
          $t(
            "CalendarViews.Views",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithLocalData"
          :data="viewData"
          :allowEmpty="false"
          :selectedData="selectedViews"
          @onMultipleSelectChange="onChangeViewData"
          :isMultiple="true"
        />
      </div>
      <div class="col col-md-4">
        <label for="DefaultView" class="form-label required">{{
          $t(
            "CalendarViews.DefaultView",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithLocalData"
          :data="defaultViewData"
          :allowEmpty="false"
          :selectedData="selectedDefaultView"
          @onChange="onChangeForDefaultViewData"
        />
      </div>
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col col-md-4">
        <label for="TimeLineField" class="form-label">{{
          $t(
            "CalendarViews.TimeLineField",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          :selectedData="selectedTimeLine"
          :isDetailIconShow="true"
          :routeLink="
            String.format(
              '#/CustomObject/EditField/{0}&fieldId=',
              this.$route.params.customObjectId
            )
          "
          @onChange="onChangeTimeLine"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryFields?parentId={0}&coId={0}',
              this.$route.params.customObjectId
            )
          "
        />
      </div>
      <div class="col col-md-4">
        <label for="MinTime" class="form-label required">{{
          $t(
            "CalendarViews.MinTime",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <DatePicker
          type="time"
          id="MinTime"
          :displayFormat="$store.getters._timeFormat"
          :isRequired="true"
          :allowClear="false"
          :value="calendarViewData.minTime"
          @valueChanged="onChangeTimeMinPicker"
        />
      </div>
      <div class="col col-md-4">
        <label for="MaxTime" class="form-label required">{{
          $t(
            "CalendarViews.MaxTime",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <DatePicker
          type="time"
          id="MaxTime"
          :displayFormat="$store.getters._timeFormat"
          :isRequired="true"
          :allowClear="false"
          :value="calendarViewData.maxTime"
          @valueChanged="onChangeTimeMaxPicker"
        />
      </div>
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col col-md-4">
        <label for="ColorField" class="form-label">{{
          $t(
            "CalendarViews.ColorField",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangeColorField"
          :selectedData="selectedColorCodeField"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryFields?coId={0}',
              this.$route.params.customObjectId
            )
          "
        />
      </div>
      <div class="col col-md-4">
        <label for="DataFetchSize" class="form-label required"
          >{{
            $t(
              "CalendarViews.DataFetchSize",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="number"
          class="form-control"
          id="DataFetchSize"
          v-model="calendarViewData.dataFetchSize"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          min="1"
          required
        />
      </div>
      <div class="col col-md-4">
        <label
          for="PageDesignerPublicId"
          class="form-label"
          :class="{
            required:
              calendarViewData.isInsertOnCalendar ||
              calendarViewData.isEditableOnCalendar,
          }"
          >{{
            $t(
              "CalendarViews.PageLayout",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label
        >
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          :selectedData="selectedPageLayout"
          :isDetailIconShow="true"
          :allowEmpty="
            !calendarViewData.isInsertOnCalendar &&
            !calendarViewData.isEditableOnCalendar
          "
          :routeLink="
            String.format(
              '#/CustomObject/EditPageLayout/{0}&pageLayoutId=',
              this.$route.params.customObjectId
            )
          "
          @onChange="onChangePageLayout"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryPageLayouts?parentId={0}&coId={0}',
              this.$route.params.customObjectId
            )
          "
        />
      </div>
    </div>
    <div class="row rows-cols-1 mt-3">
      <div class="col col-md-4">
        <label
          for="LightboxRate"
          class="form-label"
          :class="{
            required:
              calendarViewData.isInsertOnCalendar ||
              calendarViewData.isEditableOnCalendar,
          }"
          >{{
            $t(
              "CalendarViews.LightboxRate",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <h6 class="badge badge-warning text-dark ms-1">
          {{ calendarViewData.lightBoxRatio }}
        </h6>
        <input
          id="LightboxRate"
          type="range"
          class="form-range"
          min="1"
          max="100"
          v-model="calendarViewData.lightBoxRatio"
          @oninput="this.calendarViewData.lightBoxRatio = this.value"
        />
      </div>
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col col-md-4">
        <div class="form-check">
          <label for="IsInsertOnCalendar" class="form-label">{{
            $t(
              "CalendarViews.IsInsertOnCalendar",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <input
            class="form-check-input"
            type="checkbox"
            id="IsInsertOnCalendar"
            v-model="calendarViewData.isInsertOnCalendar"
          />
        </div>
        <div class="form-check">
          <label for="IsEditableOnCalendar" class="form-label">{{
            $t(
              "CalendarViews.IsEditableOnCalendar",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <input
            class="form-check-input"
            type="checkbox"
            id="IsEditableOnCalendar"
            v-model="calendarViewData.isEditableOnCalendar"
          />
        </div>
        <div class="form-check">
          <label for="IsActive" class="form-label">{{
            $t(
              "BaseModelFields.IsActive",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <input
            class="form-check-input"
            type="checkbox"
            id="IsActive"
            v-model="calendarViewData.isActive"
          />
        </div>
      </div>
    </div>
    <ActionButtons
      v-if="$isMobile()"
      @methodSave="onSubmit('btn-save')"
      :isNewRecord="false"
    />
  </form>
</template>
<script>
import $ from "jquery";
export default {
  name: "CustomObjectEditCalendar",
  components: {},
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save",
      errors: [],
      calendarViewData: {},
      selectedStartDate: [],
      selectedEndDate: [],
      selectedTitleObjects: [],
      selectedViews: [],
      selectedDefaultView: [],
      selectedColorCodeField: [],
      selectedTimeLine: [],
      selectedPageLayout: [],
      endDateData: [],
      viewData: [
        { key: "1", value: "dayGridWeek" },
        { key: "2", value: "dayGridMonth" },
        { key: "3", value: "timeGridDay" },
        { key: "4", value: "timeGridWeek" },
        { key: "5", value: "listDay" },
        { key: "6", value: "listMonth" },
        { key: "7", value: "listYear" },
        { key: "8", value: "timeLineDay" },
        { key: "9", value: "timeLineWeek" },
        { key: "10", value: "timeLineMonth" },
        { key: "11", value: "timeLineYear" },
        { key: "12", value: "resourceTimelineDay" },
        { key: "13", value: "resourceTimelineWeek" },
        { key: "14", value: "resourceTimelineMonth" },
        { key: "15", value: "resourceTimelineYear" },
        { key: "16", value: "resourceDayGridDay" },
        { key: "17", value: "resourceDayGridWeek" },
        { key: "18", value: "resourceDayGridMonth" },
        { key: "19", value: "resourceTimeGridDay" },
        { key: "20", value: "resourceTimeGridMonth" },
      ],
      defaultViewData: [],
      nullObject: [{ key: null, value: null }],
      orjEndDateData: [],
    };
  },
  methods: {
    onChangeColorField(selected) {
      this.calendarViewData.colorCodeFieldPublicId = selected.key;
    },
    onChangeTimeMinPicker: function (date) {
      this.calendarViewData.minTime = date;
      if (!String.isNullOrWhiteSpace(date)) {
        this.calendarViewData.minTime = this.$moment(date).format(
          this.$store.getters._timeFormat
        );
      }
    },
    onChangeTimeMaxPicker: function (date) {
      this.calendarViewData.maxTime = date;
      if (!String.isNullOrWhiteSpace(date)) {
        this.calendarViewData.maxTime = this.$moment(date).format(
          this.$store.getters._timeFormat
        );
      }
    },
    onChangeStartDate: function (selected) {
      this.calendarViewData.startDateFieldPublicId = selected.key;
      // const constEndDate = this.endDateData.filter(x => x.key != selected.key);
      // this.endDateData = constEndDate;
      this.endDateData = this.orjEndDateData;
      if (selected.key != null) {
        this.endDateData = this.endDateData.filter(
          (x) => x.key != selected.key
        );
      }
    },
    onChangeEndDate: function (selected) {
      this.calendarViewData.endDateFieldPublicId = selected.key;
    },
    onChangeTitleObject: function (ids) {
      this.calendarViewData.titleFieldPublicIds = ids;
    },
    onChangeForDefaultViewData: function (selected) {
      this.calendarViewData.defaultView = selected.value;
    },
    onChangeTimeLine: function (selected) {
      this.calendarViewData.timeLineFieldPublicId = selected.key;
    },
    onChangePageLayout: function (selected) {
      this.calendarViewData.pageDesignerPublicId = selected.key;
    },
    onChangeViewData(ids) {
      var self = this;
      this.defaultViewData = [];
      if (
        !String.isNullOrWhiteSpace(ids) &&
        ids.includes(this.$systemSeparator)
      ) {
        var arrayOfViews = [];
        arrayOfViews = ids.split(this.$systemSeparator);
        var viewName = "";
        arrayOfViews.forEach((v) => {
          var defaultTempView = this.viewData.find((f) => f.key == v);
          this.defaultViewData.push(defaultTempView);
          viewName += defaultTempView.value + self.$systemSeparator;
        });
        viewName = viewName.slice(0, -1);
        this.calendarViewData.views = viewName;
      } else {
        var defaultTemp = this.viewData.find((f) => f.key == ids);
        if (!defaultTemp) {
          this.calendarViewData.views = null;
          return;
        }
        this.defaultViewData.push(defaultTemp);
        this.calendarViewData.views = defaultTemp.value;
      }
    },
    getEndDateData() {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-SummaryCalendarViewableFields?parentId={0}&coId={0}",
            this.$route.params.customObjectId
          )
        )
        .then((response) => {
          this.endDateData = response.data.items;
          this.orjEndDateData = response.data.items;
          if (this.selectedStartDate.length > 0) {
            var selectedKey = this.selectedStartDate[0].key;
            this.endDateData = this.endDateData.filter(
              (x) => x.key != selectedKey
            );
          }
        });
    },
    onSubmit(buttonName) {
      var form = $(".form-calendar-edit");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-CalendarViewUpdate", { ...this.calendarViewData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.$router.push(
              "/CustomObject/CalendarViews/" + this.$route.params.customObjectId
            );
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
    getCalendarDetail() {
      var self = this;
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-CalendarViewGet?id={0}",
            this.$route.params.calendarViewId
          )
        )
        .then((response) => {
          this.calendarViewData = response.data;
          if (
            !String.isNullOrWhiteSpace(
              this.calendarViewData.startDateFieldPublicId
            ) &&
            !String.isNullOrWhiteSpace(this.calendarViewData.startDateFieldName)
          ) {
            this.selectedStartDate.push({
              key: this.calendarViewData.startDateFieldPublicId,
              value: this.calendarViewData.startDateFieldName,
            });
            this.getEndDateData();
          }
          if (
            !String.isNullOrWhiteSpace(
              this.calendarViewData.endDateFieldPublicId
            ) &&
            !String.isNullOrWhiteSpace(this.calendarViewData.endDateFieldName)
          ) {
            this.selectedEndDate.push({
              key: this.calendarViewData.endDateFieldPublicId,
              value: this.calendarViewData.endDateFieldName,
            });
          }
          if (
            !String.isNullOrWhiteSpace(
              this.calendarViewData.timelineFieldPublicId
            ) &&
            !String.isNullOrWhiteSpace(this.calendarViewData.timelineFieldName)
          ) {
            this.selectedTimeLine.push({
              key: this.calendarViewData.timelineFieldPublicId,
              value: this.calendarViewData.timelineFieldName,
            });
          }
          if (
            !String.isNullOrWhiteSpace(
              this.calendarViewData.pageDesignerPublicId
            ) &&
            !String.isNullOrWhiteSpace(this.calendarViewData.pageDesignerName)
          ) {
            this.selectedPageLayout.push({
              key: this.calendarViewData.pageDesignerPublicId,
              value: this.calendarViewData.pageDesignerName,
            });
          }
          if (
            !String.isNullOrWhiteSpace(
              this.calendarViewData.titleFieldPublicIds
            ) &&
            !String.isNullOrWhiteSpace(this.calendarViewData.titleFieldNames)
          ) {
            var listTitleFieldPublidIds = [];
            var listTitleFieldNames = [];
            if (
              this.calendarViewData.titleFieldPublicIds.includes(
                this.$systemSeparator
              )
            ) {
              listTitleFieldPublidIds =
                this.calendarViewData.titleFieldPublicIds.split(
                  this.$systemSeparator
                );
              listTitleFieldNames = this.calendarViewData.titleFieldNames.split(
                this.$systemSeparator
              );
            } else {
              listTitleFieldPublidIds.push(
                this.calendarViewData.titleFieldPublicIds
              );
              listTitleFieldNames.push(this.calendarViewData.titleFieldNames);
            }
            if (listTitleFieldPublidIds.length > 0) {
              $.each(listTitleFieldPublidIds, function (i, v) {
                self.selectedTitleObjects.push({
                  key: v,
                  value: listTitleFieldNames[i],
                });
              });
              self.calendarViewData.titleFieldPublicIds =
                listTitleFieldPublidIds.join(this.$systemSeparator);
            }
          }
          if (!String.isNullOrWhiteSpace(this.calendarViewData.views)) {
            var listViews = [];
            if (this.calendarViewData.views.includes(this.$systemSeparator)) {
              listViews = this.calendarViewData.views.split(
                this.$systemSeparator
              );
            } else {
              listViews.push(this.calendarViewData.views);
            }
            if (listViews.length > 0) {
              $.each(listViews, function (i, v) {
                var view = self.viewData.find((f) => f.value == v);
                self.selectedViews.push(view);
                self.defaultViewData.push(view);
              });
              self.calendarViewData.views = listViews.join(
                this.$systemSeparator
              );
            }
          }
          if (!String.isNullOrWhiteSpace(this.calendarViewData.defaultView)) {
            var view = this.viewData.find(
              (f) => f.value == this.calendarViewData.defaultView
            );
            this.selectedDefaultView.push(view);
            self.calendarViewData.defaultView = view.value;
          }

          if (
            !String.isNullOrWhiteSpace(
              this.calendarViewData.colorCodeFieldPublicId
            ) &&
            !String.isNullOrWhiteSpace(this.calendarViewData.colorCodeFieldName)
          ) {
            this.selectedColorCodeField.push({
              key: this.calendarViewData.colorCodeFieldPublicId,
              value: this.calendarViewData.colorCodeFieldName,
            });
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
  mounted() {
    this.getCalendarDetail();
  },
};
</script>
